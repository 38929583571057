<template>
  <div class="score-chip">
    <!-- If there's no score yet -->
    <template v-if="score === null"> - </template>

    <!-- If the score is in negatives -->
    <template v-else-if="score < 0">
      <template v-if="score >= -30">
        <v-chip color="orange lighten-4" text-color="orange darken-4">
          <!-- <v-icon left>
            sentiment_dissatisfied
          </v-icon> -->
          😞 &nbsp;{{ score }}%
        </v-chip>
      </template>

      <template v-else>
        <v-chip color="red lighten-4" text-color="red darken-4">
          <!-- <v-icon left>
            sentiment_very_dissatisfied
          </v-icon> -->
          😕 &nbsp;{{ score }}%
        </v-chip>
      </template>
    </template>

    <!-- If the score is in positives -->
    <template v-else>
      <template v-if="score <= 30">
        <v-chip color="lime lighten-4" text-color="lime darken-4">
          <!-- <v-icon left>
            sentiment_neutral
          </v-icon> -->
          🙂 &nbsp;{{ score }}%
        </v-chip>
      </template>

      <template v-else>
        <v-chip color="green lighten-4" text-color="green darken-4">
          <!-- <v-icon left>
            sentiment_very_satisfied
          </v-icon> -->
          😌 &nbsp;{{ score }}%
        </v-chip>
      </template>
    </template>
  </div>
</template>

<script>
// Export the SFC
export default {
  // Name of the component
  name: "ScoreChip",

  // Accept the incoming data
  props: {
    score: {
      type: Number,
      required: false,
      default: null
    }
  }
}
</script>

<style lang="stylus">
.score-chip
  .v-chip__content
    font-size 1.2em
    font-weight bold
</style>
